// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agenda-tsx": () => import("./../src/pages/agenda.tsx" /* webpackChunkName: "component---src-pages-agenda-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-speakers-tsx": () => import("./../src/pages/speakers.tsx" /* webpackChunkName: "component---src-pages-speakers-tsx" */),
  "component---src-pages-why-attend-tsx": () => import("./../src/pages/why-attend.tsx" /* webpackChunkName: "component---src-pages-why-attend-tsx" */),
  "component---src-templates-speaker-details-page-tsx": () => import("./../src/templates/SpeakerDetailsPage.tsx" /* webpackChunkName: "component---src-templates-speaker-details-page-tsx" */),
  "component---src-templates-speaker-instructions-page-tsx": () => import("./../src/templates/SpeakerInstructionsPage.tsx" /* webpackChunkName: "component---src-templates-speaker-instructions-page-tsx" */)
}

